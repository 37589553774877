<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";
import MainForm from "@/components/MainForm.vue";
import FormTitle from "@/components/FormTitle.vue";

export default {
	components: {
		SecondaryButton,
		MainForm,
		FormTitle,
	},
	data() {
		return {
			listed: false,
			showForm: false,
			action: "Agregar",
			periods: [],
			period: {
				id: { name: "Id", value: null },
				name: { name: "Nombre", value: null },
				since: { name: "Desde", value: null },
				until: { name: "Hasta", value: null },
				max_votes: { name: "Votos máximos", value: null },
			},
		};
	},
	created() {
		this.listed = false;
		this.loadPeriods();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			//READ_PERIODS
			readPeriods: "getPeriods/readPeriods",
			//READ_ERRORS
			getPeriodsError: "getPeriods/readError",
			createError: "createPeriod/readError",
			updateError: "updatePeriod/readError",
			deleteError: "deletePeriod/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//SET_PERIODS
			setCreatePeriod: "createPeriod/SET_PERIOD",
			setUpdatePeriod: "updatePeriod/SET_PERIOD",
			setDeleteId: "deletePeriod/SET_ID",
			setUpdateId: "updatePeriod/SET_ID",
			//SET_ERRORS
			setGetError: "getPeriods/SET_ERROR",
			setCreateError: "createPeriod/SET_ERROR",
			setUpdateError: "updatePeriod/SET_ERROR",
			setDeleteError: "deletePeriod/SET_ERROR",
		}),
		...mapActions({
			getPeriods: "getPeriods/getPeriods",
			createPeriod: "createPeriod/createPeriod",
			updatePeriod: "updatePeriod/updatePeriod",
			deletePeriod: "deletePeriod/deletePeriod",
		}),
		reverse(string) {
			return string.split("-").reverse().join("-");
		},
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadPeriods() {
			this.clearErrors();
			this.getPeriods()
				.then((result) => {
					if (result) {
						this.periods = this.readPeriods;
					}
				})
				.finally(() => {
					this.listed = true;
				});
		},
		toggleForm() {
			this.clearErrors();
			this.showForm = !this.showForm;
		},
		update(data) {
			this.clearErrors();
			this.toggleForm();
			this.action = "Actualizar";
			for (let r in this.period) {
				this.period[r]["value"] = data[r];
			}
		},
		remove(period) {
			this.clearErrors();
			this.setLoading(true);
			this.$swal({
				title: "¿Desea eliminar este Periodo de Reconocimiento?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.setDeleteId(period.id.value || period.id);
					this.deletePeriod().then((result) => {
						if (result) {
							this.loadPeriods();
							this.$swal({
								icon: "success",
								title: `El Periodo ${
									period.name.value || period.name
								} ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
							this.clear();
							if (this.showForm) this.toggleForm();
						}
					});
				}
			});

			this.setLoading(false);
		},
		submit() {
			this.clearErrors();
			this.setLoading(true);
			let period = this.period;
			let output = {};
			for (let p in period) {
				output[p] = period[p].value;
			}

			if (this.action == "Agregar") {
				this.setCreatePeriod(output);
				this.createPeriod().then((result) => {
					if (result) {
						this.loadPeriods();
						this.toggleForm();
						this.$swal({
							icon: "success",
							title: `El Periodo de Reconocimiento ${output.name} ha sido creado con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
						this.clear();
					}
				});
			} else {
				this.setUpdateId(output.id);
				this.setUpdatePeriod(output);

				this.updatePeriod().then((result) => {
					if (result) {
						this.loadPeriods();
						this.toggleForm();
						this.$swal({
							icon: "success",
							title: `El Periodo de Reconocimiento ${output.name} se actualizó con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
						this.clear();
					}
				});
			}
			this.setLoading(false);
		},
		clear() {
			for (let r in this.period) {
				this.period[r].value = null;
			}
			this.action = "Agregar";
		},
		clearErrors() {
			this.setGetError(null);
			this.setCreateError(null);
			this.setDeleteError(null);
			this.setUpdateError(null);
		},
	},
};
</script>

<template>
	<div class="main">
		<div
			v-if="showForm"
			class="modal-form fixed right-0 top-0 p-3 md:p-5 w-full h-full z-30 overflow-y-auto"
		>
			<div
				class="content-wrap bg-white border-2 border-gray px-6 pt-10 pb-6 shadow-md absolute max-w-3xl mx-auto right-0 left-0"
			>
				<SecondaryButton
					@click.native="[toggleForm(), clear()]"
					text="x"
					buttonColor="bg-red"
					class="close rounded-full h-10 absolute right-6 top-6"
				/>
				<form-title
					:action="action"
					text="Periodo de "
					textBold="Reconocimiento"
					:name="period.name.value"
				></form-title>
				<!-- DISPLAY FORM -->
				<form class="grid grid-cols-2 gap-5 items-center">
					<label
						class="text-lg"
						:class="{
							'col-span-2': key != 'since' && key != 'until',
						}"
						:for="key"
						v-for="(p, key, index) in period"
						:key="key"
						v-show="key != 'id'"
						ref="labels"
					>
						<span class="block">{{ p.name }}</span>
						<textarea
							v-if="key != 'since' && key != 'until'"
							:ref="index"
							:required="key != 'id'"
							class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
							:name="key"
							:placeholder="p.name"
							v-model="p.value"
							rows="2"
							v-capitalizeInput
						/>
						<input
							v-if="key == 'since' || key == 'until'"
							type="date"
							class="w-full block px-4 py-2 text-lg rounded-md border border-gray bg-gray-light"
							required
							:name="p.name"
							:id="key"
							v-model="p.value"
						/>
					</label>
					<span
						v-if="getPeriodsError"
						class="block mt-5 red text-center b col-span-2"
						>{{ getPeriodsError }}</span
					>
					<span
						v-if="deleteError"
						class="block mt-5 red text-center b col-span-2"
						>{{ deleteError }}</span
					>
					<span
						v-if="createError"
						class="block mt-5 red text-center b col-span-2"
						>{{ createError }}</span
					>
					<span
						v-if="updateError"
						class="block mt-5 red text-center b col-span-2"
						>{{ updateError }}</span
					>
					<div
						class="col-span-2 my-6 flex flex-col items-center md:flex-row justify-evenly"
					>
						<SecondaryButton
							v-if="action == 'Actualizar'"
							@click.native="remove(period)"
							text="Eliminar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							@click.native="[toggleForm(), clear()]"
							v-else
							text="Cancelar"
							buttonColor="bg-red"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/>
						<SecondaryButton
							:text="action"
							@click.native="submit"
							class="flex-none"
						/>
					</div>
				</form>
			</div>
		</div>
		<main-form
			v-on:toggleForm="toggleForm"
			:action="action"
			text="Periodos de"
			textBold="Renoconocimiento"
		></main-form>
		<table
			v-if="!isLoading && periods.length > 0"
			id="no-more-tables"
			class="block lg:table table-auto w-full"
		>
			<thead class="block lg:table-header-group elektra-bg-red">
				<tr class="block lg:table-row border border-gray">
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						v-for="(field, key) in period"
						:key="key"
						v-show="key != 'id'"
					>
						{{ field.name }}
					</th>
					<th
						class="block lg:table-cell b text-white border-r py-3 px-1"
					>
						Acciones
					</th>
				</tr>
			</thead>
			<tbody class="block md:flex flex-wrap lg:table-row-group">
				<tr
					class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
					:class="
						periods.length > 1
							? 'w-full md:w-1/2 lg:w-full'
							: 'w-full'
					"
					v-for="(p, i) in periods"
					:key="i"
				>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						:class="{ 'green b': key == 'id' }"
						v-for="(field, key) in period"
						:key="key"
						v-show="key != 'id'"
					>
						{{
							key == "since" || key == "until"
								? reverse(p[key])
								: p[key]
						}}
					</td>
					<td
						class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
					>
						<div class="w-full flex justify-evenly items-center">
							<span
								class="title-hint"
								aria-label="Da clic aquí para editar este Periodo"
							>
								<font-awesome-icon
									@click="update(p)"
									class="green text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-pen-to-square"
								/>
							</span>
							<span
								class="title-hint"
								aria-label="Da clic aquí para eliminar este Periodo"
							>
								<font-awesome-icon
									@click="remove(p)"
									class="red text-3xl md:text-2xl cursor-pointer"
									icon="fa-solid fa-trash-can"
								/>
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron periodos</span
		>
	</div>
</template>

<style lang="scss" scoped>
// 	FIELDS LABELS
@media (max-width: 1023px) {
	$fields: 1 "Nombre", 2 "Desde", 3 "Hasta", 4 "Máximo de Votos";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i + 1}):before {
			content: $field;
		}
	}
}
</style>
